import React from "react"
import "../styles/scss/consensus-labs-announce.scss"

export function ConsensusLabsAnnounce() {
  return (
    <section className="section" id="consensus-labs-announcer">
      <div className="container">
        <div className="is-flex is-flex-direction-column is-align-content-stretch">
          <div className="mb-4">
            <img
              width={218}
              height={34}
              src="/consensus_labs_logo.svg"
              alt="consensus labs logo"
            />
          </div>
          <div>
            <b>
              After 6 amazing years, 50+ projects delivered, and 100+ happy
              clients around the globe, Doganbros has completed its mission.
            </b>
            <br />
            We&lsquo;re leveling up as <b>Consensus Labs Swiss!</b> Come along
            for the ride and check out what&lsquo;s next at{" "}
            <b>
              <a
                href="https://consensuslabs.ch"
                target="_blank"
                rel="noreferrer"
              >
                consensuslabs.ch
              </a>{" "}
              🚀
            </b>
          </div>
        </div>
      </div>
    </section>
  )
}
