import React from "react"
import { Helmet } from "react-helmet"
import Contact from "./Contact"
import Footer from "./Footer"
import Header from "./Header"
import "../styles/scss/index.scss"
import Cookie from "./Cookie"
import { ConsensusLabsAnnounce } from "./ConsensusLabsAnnounce"

const Layout = ({ children, showContact = true }) => {
  return (
    <>
      <Helmet />
      <ConsensusLabsAnnounce />
      <div className="sticky-container">
        <Header />
      </div>
      {children}
      {showContact ? <Contact /> : null}
      <Cookie />
      <Footer />
    </>
  )
}

export default Layout
