import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

const Header = () => {
  const {
    strapiSiteMetaData: {
      data: { attributes: strapiSiteData },
    },
    strapiServiceSection: {
      data: {
        attributes: { title: serviceTitle },
      },
    },
    strapiSolutionsSection: {
      data: {
        attributes: { title: solutionsTitle },
      },
    },
    strapiTechnologiesSection: {
      data: {
        attributes: { title: technologiesTitle },
      },
    },
    strapiBlogs: { data: strapiBlogData },
  } = useStaticQuery(
    graphql`
      query HeaderQuery {
        strapiSiteMetaData {
          data {
            attributes {
              logo {
                data {
                  attributes {
                    localFile {
                      url
                    }
                  }
                }
              }
            }
          }
        }
        strapiServiceSection {
          data {
            attributes {
              title
            }
          }
        }
        strapiSolutionsSection {
          data {
            attributes {
              title
            }
          }
        }
        strapiTechnologiesSection {
          data {
            attributes {
              title
            }
          }
        }
        strapiBlogs {
          data {
            id
          }
        }
      }
    `
  )

  const links = [
    {
      id: 1,
      title: serviceTitle,
      to: "/#services",
      link: true,
    },
    { id: 2, title: technologiesTitle, to: "/#technologies", link: true },
    { id: 3, title: solutionsTitle, to: "/#solutions", link: true },
    { id: 4, title: "Testimonials", to: "/#testimonials", link: true },
    { id: 5, title: "Who are we?", to: "/#who-are-we", link: true },
    ...(strapiBlogData.length
      ? [{ id: 6, title: "Blog", to: "/blog", link: true }]
      : []),
    { id: 7, title: "Contact us", to: "/contact", link: true },
  ]

  const [isActive, setIsActive] = useState(false)

  return (
    <nav
      className="navbar is-dark"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <Link className="navbar-item" to="/">
            <img
              src={strapiSiteData.logo.data.attributes.localFile.url}
              alt="Doganbros Logo"
              className="mr-3 image is-32x32"
            />
            <span className="is-size-5">Doganbros</span>
          </Link>

          <button
            className={`navbar-burger ${isActive ? "is-active" : ""}`}
            aria-label="menu"
            aria-expanded="false"
            onClick={() => setIsActive(!isActive)}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </button>
        </div>

        <div
          className={`navbar-menu has-background-dark ${
            isActive ? "is-active" : ""
          }`}
        >
          <div className="navbar-end">
            {links.map(({ id, title, to, link }) =>
              link ? (
                <Link
                  key={id}
                  to={to}
                  className="navbar-item"
                  onClick={() => setIsActive(false)}
                >
                  {title}
                </Link>
              ) : (
                <a
                  key={id}
                  href={to}
                  rel="noopener noreferrer"
                  className="navbar-item"
                  target="_blank"
                  onClick={() => setIsActive(false)}
                >
                  {title}
                </a>
              )
            )}
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Header
